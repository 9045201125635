<template>
  <section class="add-why-us-about">
    <div class="row flex-grow">
      
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"> Why Us</h4>
            <!-- <p class="card-description"> About Avant Garde </p> -->
            <form class="forms-sample">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea class="form-control"   rows="4"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Update</button>
            </form>
          </div>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>

export default {
  name: 'add-why-us-about',
  components: {
  },
  data() {
    return {
    };
  }
}
</script>

<style scoped></style>